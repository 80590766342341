// Typography
// --------------------------------------------------

$base-font: 'Lato', Calibri, Arial, sans-serif;
$heading-font: $base-font;
$caption-font: $base-font;
$code-font: monospace;
$alt-font: serif;

$doc-font-size: 16;
$doc-line-height: 26;


// set-up the body font-size / line-height
body {
	font-size: 0px + $doc-font-size;
}


// Colors
// --------------------------------------------------
$base-color				 : rgba(#222,0.8);
$header-color			 : rgba(#000,0.8);
$sub-item-color			 : rgba(#000, 0.1);
//$header-margin			 : #F45145;
$header-margin			 : #308cbc;
$menu-top				 : white;
$menu-height			 : 70px;
//$menu-overflow			 : #2E2E2E;
$menu-overflow			 : white;
$body-color      		 : #e8e8e8;
$text-color      		 : rgba(#222,0.8);
$comp-color 	 		 : complement(#222);
$border-color    		 : lighten(#222,60);
$white           		 : #fff;
$black           		 : #000;
$link-color 		     : rgba(#222,0.8);

$primary			     : #222;
$success			     : #5cb85c;
$warning			     : #dd8338;
$danger				     : #C64537;
$info				     : #308cbc;


// Screen Size
// --------------------------------------------------

$mobile				 : "only screen and (min-width: 0em) and (max-width: 47em)";
$small				 : "only screen and (min-width: 30em)";
$medium              : "only screen and (min-width: 48em)";
$large               : "only screen and (min-width: 62.5em)";
