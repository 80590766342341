body {
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: $body-color;
	overflow-x: hidden;
}

// Main
// --------------------------------------------------
.entry,
.hentry {
	@include clearfix;
	h1, h2, h3, h4, h5, h6, p, li {
		word-wrap: break-word;
	}
    background-color: white;
}
.hentry-dark {
	background-color: $header-color;
}
.entry-content {
	@include font-size(16);
	// Dotted line underlines for links
	p > a,
	li > a {
		color: $header-margin;
		border-bottom: 1px dotted lighten($link-color, 50);
		&:hover {
			border-bottom-style: solid;
            color: #308cbc;
		}
	}
    img {
        display: block;
        margin-left: auto;
        margin-right: auto
    }
    img.listing {
        margin-left: 0;
        display: inherit;
        width: auto;
        height: auto;
        max-height: 40px;
        max-height: 40px;
    }
}

///sections

.content-header-title {
	text-align: center;
	margin: 30px 0 0;
	h1 {
		margin: 10px 20px;
		font-weight: 700;
		@include font-rem(32);
		color: lighten($base-color,20);
		@media #{$medium} {
			@include font-rem(48);
		}
		@media #{$large} {
			@include font-rem(60);
		}
	}
	h2 {
		margin: 0;
		@include font-rem(18);
		text-transform: uppercase;
		color: lighten($base-color,40);
		@media #{$medium} {
			@include font-rem(24);
		}
	}
	p {
		color: lighten($base-color,20);
	}
}


// Feature Image Caption
.image-credit {
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 0 auto;
	max-width: 440px;
	padding: 10px 15px;
	background-color: rgba($base-color,0.5);
	color: $white;
	@include font-rem(12);
	text-align: right;
	@include border-radius(3px,0,0,3px);
	z-index: 10;
	@media #{$medium} {
		max-width: 760px;
	}
	@media #{$large} {
		max-width: 960px;
	}
	a {
		color: $white;
		text-decoration: none;
	}
}

// Single Post and Page
// --------------------------------------------------
.entry-meta {
	@include font-rem(12);
	text-transform: uppercase;
	color: lighten($base-color,60);
	a {
		color: lighten($base-color,60);
	}
	.vcard {
		&:before {
			content: " by ";
		}
	}
	.tag {
		display: inline-block;
		margin: 4px;
		color: $white;
		@include rounded(3px);
		background-color: lighten($base-color,50);
		span {
			float: left;
			padding: 2px 6px;
		}
		.count {
			background-color: lighten($base-color,40);
			@include border-radius(3px,3px,0,0);
		}
		&:hover {
			background-color: lighten($base-color,40);
		}
	}
	.entry-reading-time {
		float: right;
	}
}
header .entry-meta {
	display: none; // hide header meta on small screens
	@media #{$medium} {
		display: block;
	}
}
#menu{
  margin-bottom: 0px;
}
#post,
#page {
	.entry-content {
		margin: 0 0 20px 0;
		padding: 20px 15px;
		background-color: $white;
		//box-shadow: 0 0 0 0, 0 6px 12px rgba($black,0.1);
		@include rounded(3px);
		@media #{$medium} {
			padding: 20px 30px;
		}
		@media #{$large} {
			max-width: 800px;
			margin: 0 auto 0 auto;
			padding: 20px 80px;
			> p:first-child {
				@include font-size(16); //set font size for first paragraph of page
			}
		}
	}
	#disqus_thread {
		margin: 40px 2px 20px 2px;
		padding: 10px 15px;
		background-color: $white;
		box-shadow: 0 0 0 1px rgba($border-color,0.1), 0 6px 12px rgba($black,0.1);
		@include rounded(3px);
		@media #{$medium} {
			margin-left: 10px;
			margin-right: 10px;
			padding: 20px 30px;
		}
		@media #{$large} {
			max-width: 800px;
			padding: 50px 80px;
			margin: 0 auto 30px auto;
		}
	}
	.entry-meta {
		margin: 50px 30px 30px;
		text-align: center;
	}
}
.entry-tags {
	display: block;
	margin-bottom: 6px;
}
.tag-heading,
.year-heading {
	margin-top: 0;
}
// Go to the exact location of bookmarks in tags, categories
.anchor-bookmark{
  display: block;
  height: $menu-height; /*same height as header*/
  margin-top: -$menu-height; /*same height as header*/
  visibility: hidden;
}
// Permalink icon for link post
.permalink {
	margin-right: 7px;
}
// Post Pagination Module
.pagination {
	margin: 20px 10px;
	text-align: center;
	ul {
		display: inline;
		margin-left: 10px;
		margin-right: 10px;
	}
	li {
		padding-left: 4px;
		padding-right: 4px;
	}
	.current-page {
		font-weight: 700;
	}
}

// Read More Module
.read-more {
	position: relative;
	margin: 40px 2px 20px 2px;
	padding: 40px 15px 25px;
	background-color: $white;
	box-shadow: 0 0 0 1px rgba($border-color,0.1), 0 6px 12px rgba($black,0.1);
	@include rounded(3px);
	@media #{$medium} {
		margin: 50px 10px 20px 10px;
		padding: 50px 40px 25px;
	}
	@media #{$large} {
		max-width: 800px;
		padding: 50px 80px;
		margin: 60px auto;
	}
	text-align: center;
	@include clearfix;
}
.read-more-header {
	position: absolute;
	top: -20px;
	left: 0;
	right: 0;
	height: 35px;
	a {
		@extend .btn;
	}
}
.read-more-content {
	@include font-size(16);
	// Dotted line underlines for links
	p > a,
	li > a {
		border-bottom: 1px dotted lighten($link-color, 50);
		&:hover {
			border-bottom-style: solid;
		}
	}
	h3 {
		margin: 0;
		@include font-rem(28);
		a {
			color: $text-color;
		}
		@media #{$medium} {
			@include font-rem(36);
		}
	}
}
.author-img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	box-shadow: 0 0 8px rgba(0, 0, 0, .8);
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .8);
	-moz-box-shadow: 0 0 8px rgba(0, 0, 0, .8);
}
.author-container{
	display: flex;
}
.author-bio{
	padding-top: 30px;
	padding-left: 20px;
	flex-grow: 1;
}
ul.social-buttons li a {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    outline: 0;
	  color: #fff;
    background-color: #222;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

ul.social-buttons li:hover a{
		transform: scale(1.2, 1.2);;
}
ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
    color: #222;
    background-color: #fec503;
}
.read-more-list {
	border-top: solid 2px lighten($base-color,80);
}
.list-item {
	width: 100%;
	text-align: left;
	h4 {
		@include font-rem(18);
		margin-bottom: 0;
	}
	span {
		display: block;
		@include font-rem(14);
		color: lighten($base-color,50);
	}
	@media #{$medium} {
		width: 49%;
		float: left;
		&:nth-child(2) {
			text-align: right;
		}
	}
}

// Post Index
// --------------------------------------------------
#post-index {
	#main {
		margin: 40px 2px 20px 2px;
		@media #{$medium} {
			margin-left: 20px;
			margin-right: 20px;
		}
		@media #{$large} {
			max-width: 800px;
			margin-top: 50px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	article {
		background-color: $white;
		box-shadow: 0 0 0 0, 0 6px 12px rgba($base-color,0.1);
		@include rounded(3px);
		margin-bottom: 20px;
		padding: 25px 15px;
		@media #{$medium} {
			padding: 30px;
		}
		@media #{$large} {
			margin-bottom: 30px;
			padding: 50px 80px;
		}
	}
}
.entry-image-index {
	margin-top: -25px;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 10px;
	position: relative;
	@media #{$medium} {
		margin-top: -30px;
		margin-left: -30px;
		margin-right: -30px;
		margin-bottom: 15px;
	}
	@media #{$large} {
		margin-top: -50px;
		margin-left: -80px;
		margin-right: -80px;
		margin-bottom: 20px;
	}
	img {
		@include border-radius(3px,0,0,3px); // round image corners
	}
}

// Footer
// --------------------------------------------------
.footer-wrapper {
	@include clearfix;
	margin: 2em auto;
	text-align: center;
	color: lighten($text-color,20);
	a {
		color: lighten($text-color,20);
	}
}

// Social Share
// --------------------------------------------------
.socialcount {
	@include font-rem(16);
	li {
		padding-left: 10px;
		padding-right: 10px;
	}
	p > a,
	li > a {
		border-bottom-width: 0;
	}
}


// Browser Upgrade
// --------------------------------------------------
.upgrade {
	padding: 10px;
	text-align: center;
}

// Google Search
// --------------------------------------------------
#goog-fixurl {
	ul {
		list-style: none;
		margin-left: 0;
		padding-left: 0;
		li {
			list-style-type: none;
		}
	}
}
#goog-wm-qt {
	width: auto;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 8px 20px;
	display: inline-block;
	@include font-rem(14);
	background-color: $white;
	color: $text-color;
	border-width: 2px !important;
	border-style: solid !important;
	border-color: lighten($primary,50);
	@include rounded(3px);
}
#goog-wm-sb {
	@extend .btn;
}

// Header
// --------------------------------------------------
.entry-header {
	background-color: $base-color;
	background-size: cover;
	overflow: hidden;
	position: relative;
	margin: $menu-height 0 0 0;
	width: 100%;
	height: 100%;
	min-height: 200px - $menu-height;

	@media #{$medium} {
		background-color: $base-color;
		background-size: cover;
		min-height: 300px - $menu-height;
	}

}
.header-toggle{
	display: block;
    padding-right: 0;
    @media #{$large} {
		display: none;
	}
}

.header-title {
	text-align: center;
	position: absolute;
	top: 0;
	display: table;
	margin-top: 0;
	width: 100%;
	height: 200px - $menu-height;
	overflow: hidden;
	@media #{$medium} {
		height: 300px - $menu-height;
	}
	.header-title-wrap {
		display: table-cell;
		vertical-align: middle;
		margin: 0 auto;
		text-align: center;
	}
	h2 {
		color: $white;
	}
	h1 {
		font-weight: 700;
		margin: 0;
		@include font-rem(26);
		color: rgba(255, 255, 255, 1);
		text-shadow: 1px 1px 4px rgba($base-color, 0.6);
		display: inline-block;

		animation: title-fade 4s ease 1;
		-webkit-animation: title-fade 4s ease 1;
		-moz-animation: title-fade 4s ease 1;
		-o-animation: title-fade 4s ease 1;

		a {
			color: $white;
		}
		@media #{$medium} {
			@include font-rem(30);
		}
		@media #{$large} {
			@include font-rem(38);
		}
	}
	h1.post-title {
		display: block;
		@media #{$medium}{
			display: none;
		}
	}
	p {
		color: $white;
	}
}

@keyframes title-fade {
	from {
		color: rgba(255, 255, 255, 0);
		text-shadow: 1px 1px 4px rgba($base-color, 0.0);
	}
	to {
		color: rgba(255, 255, 255, 1);
		text-shadow: 1px 1px 4px rgba($base-color, 0.6);
	}
}

@-webkit-keyframes title-fade {
	from {
		color: rgba(255, 255, 255, 0);
		text-shadow: 1px 1px 4px rgba($base-color, 0.0);
	}
	to {
		color: rgba(255, 255, 255, 1);
		text-shadow: 1px 1px 4px rgba($base-color, 0.6);
	}
}

@-moz-keyframes title-fade {
	from {
		color: rgba(255, 255, 255, 0);
		text-shadow: 1px 1px 4px rgba($base-color, 0.0);
	}
	to {
		color: rgba(255, 255, 255, 1);
		text-shadow: 1px 1px 4px rgba($base-color, 0.6);
	}
}

@-o-keyframes title-fade {
	from {
		color: rgba(255, 255, 255, 0);
		text-shadow: 1px 1px 4px rgba($base-color, 0.0);
	}
	to {
		color: rgba(255, 255, 255, 1);
		text-shadow: 1px 1px 4px rgba($base-color, 0.6);
	}
}

.header-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $menu-height;
	z-index: 20;
	ul {
		margin: 0 auto;
		list-style-type: none;
		height: 100%;
		padding: 0;
		@media #{$large} {
			max-width: 900px;
		}
	}
}

.header-menu-top {
	background-color: $menu-top;
	box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.4);
}

.header-menu-overflow {
	background-color: $menu-overflow;
	z-index: 100;
	box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.4);
}

.header-menu-overflow ul li a {

}

.header-menu-top .sub-item {
	background-color: $menu-top;
}

.header-menu-overflow .sub-item {
	background-color: $menu-overflow;
}

.header-item,
.header-item-title {
	float: right;
	padding-left: 20px;
	padding-right: 20px;
	border-top-color: transparent;
	border-top-style: solid;
	border-top-width: 5px;
	box-sizing: border-box;

	a {
		vertical-align: middle;
		display: table-cell;
		height: $menu-height - 2;
	}
}

.header-item-resize {
	padding-left: 0;
	@media #{$large} {
		padding-left: 20px;
	}
}

///sections
.logo-badge {
	width: $menu-height;
	height: $menu-height;
	float: left;
	padding: 10px;
	padding-left: 1px;
	padding-right: 1px;
}

.head-badge {
	width: 0;
	height: 0;
	padding: 1px;

	@media #{$small}  {
		padding-left: 10px;
		padding-right: 10px;
		width: $menu-height;
		height: $menu-height;
	}

	circle, path {
		display: none;
		@media #{$small}  {
			display: table-cell;
		}
	}
}

.logo-text {
	height: $menu-height / 2.25;
	width: (($menu-height / 2.25) - 20) / (35 / 494.049);
	//ratio of logo SVG dimensions - used for preserving dimensions while specifying constant width and
	//height in px. For IE support.
	float: left;
	padding: 10px;
	@media #{$mobile} {
		padding: 10px 10px 10px 10px;
	}
	@media #{$small} {
		height: $menu-height / 1.75;
		width: (($menu-height / 1.75) - 20) / (35 / 494.049);
		padding: 10px 10px 10px 1px;
	}
}

.solo-container {
	display: inline-table;
	margin: 0;
	.solo-text-container {
		display: table-cell;
		vertical-align: middle;
		height: $menu-height / 1.29;
	}
	.logo-text {
		display: inline-block;
		height: ($menu-height) / 2.25;
		width: (($menu-height / 2.25) - 20) / (35 / 494.049);
		padding-left: 1px;
		padding-right: 1px;
	}
	.logo-badge {
		height: $menu-height / 1.29;
		width: $menu-height / 1.29;
		display: table-cell;
		padding-top: ($menu-height / 1.29) / 7;
		padding-bottom: ($menu-height / 1.29) / 7;
		padding-right: ($menu-height / 1.29) / 7 / 2;
	}
	@media #{$small} {
		.solo-text-container {
			display: table-cell;
			vertical-align: middle;
			height: $menu-height;
		}
		.logo-text {
			display: inline-block;
			height: ($menu-height) / 1.75;
			width: ((($menu-height) / 1.75) - 20) / (35 / 494.049);
		}
		.logo-badge {
			height: $menu-height;
			width: $menu-height;
			display: table-cell;
			padding-top: ($menu-height) / 7;
			padding-bottom: ($menu-height) / 7;
			padding-right: ($menu-height) / 7 / 2;
		}
	}
	@media #{$medium} {
		.solo-text-container {
			display: table-cell;
			vertical-align: middle;
			height: $menu-height * 2;
		}
		.logo-text {
			display: inline-block;
			height: ($menu-height * 2) / 1.75;
			width: ((($menu-height * 2) / 1.75) - 40) / (35 / 494.049);
		}
		.logo-badge {
			height: $menu-height * 2;
			width: $menu-height * 2;
			display: table-cell;
			padding-top: ($menu-height * 2) / 7;
			padding-bottom: ($menu-height * 2) / 7;
			padding-right: ($menu-height * 2) / 7 / 2;
		}
	}
}

.header-item-container {
	height: 100%;
}

.header-item {
	height: 100%;
	display: none;
	@media #{$large} {
		display: block;
	}
	-webkit-transition: background-color 0.3s, border-top-color 0.3s;
	-moz-transition: background-color 0.3s, border-top-color 0.3s;
	-o-transition: background-color 0.3s, border-top-color 0.3s;
	transition: background-color 0.3s, border-top-color 0.3s;
}

.header-item-title {
	float: left;
	border-top-width: 0px;
	height: 100%;
	padding-right: 10px;
	a.title {
		font-size: 1rem;
		padding-left: 0px;
		display: table-cell;
		vertical-align: middle;
		height: $menu-height;
		@media #{$small}{
			font-size: 1.5rem;
		}
	}
}

.header-item.active,
.header-item:hover{
  border-top-color: $header-margin;
}

.header-item:hover{
  background-color: #f2f2f2;
}

.header-item:hover .header-submenu{
	display: block;
	position: absolute;
}

.header-submenu {
	display: none;
	border-top: 2px solid $header-margin;
	width: 200px;
	z-index: 100;
	list-style-type: none;
	transform: translateX(-25%);
}

.sub-item {
	position: relative;
	padding-left: 10px;
	width: 100%;
	border-bottom: 1px solid gray;
	border-left: 1px solid;
	height: 50px;
	text-decoration: none;
}

.sub-item a{
	vertical-align: middle;
	display: table-cell;
	height: 50px;
	width: 200px;
}
.sub-item:hover {
	border-bottom-color: $header-margin;
	border-left-color: $header-margin;
    background-color: #f6f6f6;
}

.button-twitter {
	color: $white;
	position: fixed;
	margin-top: $menu-height / 2 - 20;
	margin-right: 10px;
	top: 0;
	right: 0;
	z-index: 10000000000;
	@media #{$large}{
		top: auto;
		bottom: 0;
	}

	span {
		display: none;
		@media #{$medium}{
			display: inline;
		}
	}
}

//temporary Anvil page

.anvil-showcase {
	background: #444444;
	padding: 40px;
	max-width: 300px;
	display: block;
	margin: 0 auto 0 auto;
	border-radius: 10px;
	-moz-box-shadow: inset 0 0 10px #000000;
	-webkit-box-shadow: inset 0 0 10px #000000;
	box-shadow: inset 0 0 10px #000000;
}