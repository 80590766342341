.animated{-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;-ms-animation-fill-mode:both;-o-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:1s;-moz-animation-duration:1s;-ms-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;}.animated.hinge{-webkit-animation-duration:2s;-moz-animation-duration:2s;-ms-animation-duration:2s;-o-animation-duration:2s;animation-duration:2s;}


// Fade in
// --------------------------------------------------

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}
@-moz-keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}
@-o-keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}
@keyframes fadeIn {
  0% {opacity: 0;}  
  100% {opacity: 1;}
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  } 
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}


// Bounce
// --------------------------------------------------

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(.3);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  70% {
    -moz-transform: scale(.9);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(.3);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  70% {
    -o-transform: scale(.9);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  
  80% {
    -webkit-transform: translateY(-10px);
  }
  
  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  
  80% {
    -moz-transform: translateY(-10px);
  }
  
  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  
  80% {
    -o-transform: translateY(-10px);
  }
  
  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  
  80% {
    transform: translateY(-10px);
  }
  
  100% {
    transform: translateY(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}


// Drop
// --------------------------------------------------

@-webkit-keyframes drop {
  0% {
    -webkit-transform: translateY(-500px);
  } 
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes drop {
  0% {
    -moz-transform: translateY(-500px);
  } 
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes drop {
  0% {
    -o-transform: translateY(-500px);
  } 
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes drop {
  0% {
    transform: translateY(-500px);
  } 
  100% {
    transform: translateY(0);
  }
}

.drop {
  -webkit-animation-name: drop;
  -moz-animation-name: drop;
  -o-animation-name: drop;
  animation-name: drop;
}

//logo-animation

.solo-container {

  circle, .text, .logo, .header-title-wrap.h1 {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
  }

  .text {
    transform-origin: center center;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -o-transform-origin: center center;
    -ms-transform-origin: center center;
  }
  .text {
    animation: rotatein 4s ease 1;
    -webkit-animation: rotatein 4s ease 1;
    -moz-animation: rotatein 4s ease 1;
    -o-animation: rotatein 4s ease 1;
    -ms-animation: rotatein 4s ease 1;
  }
  circle {
    transform-origin: center center;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -o-transform-origin: center center;
    -ms-transform-origin: center center;
  }
  .circle-3 {
    animation: scalesize 3s ease 1;
    -webkit-animation: scalesize 3s ease 1;
    -moz-animation: scalesize 3s ease 1;
    -o-animation: scalesize 3s ease 1;
    -ms-animation: scalesize 3s ease 1;
  }
  .circle-2 {
    animation: scalesize 2s ease 1;
    -webkit-animation: scalesize 2s ease 1;
    -moz-animation: scalesize 2s ease 1;
    -o-animation: scalesize 2s ease 1;
    -ms-animation: scalesize 2s ease 1;
  }
  .circle-1 {
    animation: scalesize 1s ease 1;
    -webkit-animation: scalesize 1s ease 1;
    -moz-animation: scalesize 1s ease 1;
    -o-animation: scalesize 1s ease 1;
    -ms-animation: scalesize 1s ease 1;
  }
  .logo {
    animation: slidein 3s cubic-bezier(0.65, 0.05, 0.36, 1) 1;
    -webkit-animation: slidein 3s cubic-bezier(0.65, 0.05, 0.36, 1) 1;
    -moz-animation: slidein 3s cubic-bezier(0.65, 0.05, 0.36, 1) 1;
    -o-animation: slidein 3s cubic-bezier(0.65, 0.05, 0.36, 1) 1;
    -ms-animation: slidein 3s cubic-bezier(0.65, 0.05, 0.36, 1) 1;

    path {
      transition: fill ease-in 0.3s;
    }

    path:hover {
      fill: #CCCCCC;
    }
  }
}
@keyframes scalesize {
  0% {
    transform: scale(0, 0) rotate(-360deg);
  }
  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}
@keyframes rotatein {
  0% {
    transform: scale(0, 0);

  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes slidein {
  0% {
    transform: translateX(-600px);

  }
  100% {
    transform: translateX(0px);
  }
}
@-webkit-keyframes scalesize {
  0% {
    -webkit-transform: scale(0, 0) rotate(-360deg);
  }
  100% {
    -webkit-transform: scale(1, 1) rotate(0deg);
  }
}
@-webkit-keyframes rotatein {
  0% {
    -webkit-transform: scale(0, 0);

  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
@-webkit-keyframes slidein {
  0% {
    -webkit-transform: translateX(-600px);

  }
  100% {
    -webkit-transform: translateX(0px);
  }
}
@-moz-keyframes scalesize {
  0% {
    -moz-transform: scale(0, 0) rotate(-360deg);
  }
  100% {
    -moz-transform: scale(1, 1) rotate(0deg);
  }
}
@-moz-keyframes rotatein {
  0% {
    -moz-transform: scale(0, 0);

  }
  100% {
    -moz-transform: scale(1, 1);
  }
}
@-moz-keyframes slidein {
  0% {
    -moz-transform: translateX(-600px);

  }
  100% {
    -moz-transform: translateX(0px);
  }
}
@-o-keyframes scalesize {
  0% {
    -o-transform: scale(0, 0) rotate(-360deg);
  }
  100% {
    -o-transform: scale(1, 1) rotate(0deg);
  }
}
@-o-keyframes rotatein {
  0% {
    -o-transform: scale(0, 0);

  }
  100% {
    -o-transform: scale(1, 1);
  }
}
@-o-keyframes slidein {
  0% {
    -o-transform: translateX(-600px);

  }
  100% {
    -o-transform: translateX(0px);
  }
}
@-ms-keyframes scalesize {
  0% {
    -ms-transform: scale(0, 0) rotate(-360deg);
  }
  100% {
    -ms-transform: scale(1, 1) rotate(0deg);
  }
}
@-ms-keyframes rotatein {
  0% {
    -ms-transform: scale(0, 0);

  }
  100% {
    -ms-transform: scale(1, 1);
  }
}
@-ms-keyframes slidein {
  0% {
    -ms-transform: translateX(-600px);

  }
  100% {
    -ms-transform: translateX(0px);
  }
}